import React from 'react';
import NotFound from '../../components/not-found';
import { setLoad } from '../../components/loading';
import Interpreter from '../../components/interpreter';
import DialogModal from '../../components/dialog-modal';
import { getWeb3 } from '../../others/helper';

export default class TransactionsItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notFound: false,
            info: [],
        }
    }

    componentDidMount = async () => {
        await setLoad(true);
        let hash = this.props.match.params.id;
        const web3 = await getWeb3();
        let tx = await web3.transactions.getTransactionByHash(hash);
        console.log(tx)
        if (!tx) {
            await this.setState({ notFound: true });
            await setLoad(false);
            return;
        }
        let info = [];
        info.push({ key: 'Hash', value: tx.hash });
        info.push({ key: 'Status', value: tx.status.toUpperCase() });
        if (tx.validator) {
            info.push({ key: 'Validator', value: <a href={`/address/${tx.validator}`}>{tx.validator}</a> });
        }
        info.push({ key: 'Version', value: tx.version });
        info.push({ key: 'Created', value: new Date(tx.created * 1000).toLocaleString() });
        info.push({ key: '-', value: '-' });
        info.push({ key: 'From', value: tx.from.map((from, i) => <span key={`from-${i}`}><a href={`/address/${from}`}>{from}</a><br /></span>) });
        info.push({ key: 'To', value: tx.to.map((to, i) => <span key={`to-${i}`}><a href={`/address/${to}`}>{to}</a><br /></span>) });
        info.push({ key: 'Tag', value: tx.tag ? tx.tag : '-' });
        info.push({ key: 'Amount', value: tx.amount.map((amount, i) => <span key={`amount-${i}`}>{amount}<br /></span>) });
        info.push({ key: 'Fee', value: tx.fee });
        if (tx.output) {
            info.push({ key: 'feeUsed', value: tx.output.feeUsed });
        }
        info.push({ key: '-', value: '-' });
        info.push({ key: 'Type', value: tx.type.toUpperCase() });
        if (tx.foreignKeys) {
            info.push({ key: 'Foreign Keys', value: tx.foreignKeys.map((key, i) => <span key={`key-${i}`}>{key}<br /></span>) });
        }
        info.push({ key: 'Preview', value: <span>{JSON.stringify(tx.data).substring(0, 30)}...</span> });
        info.push({
            key: 'Data', value: <span style={{ wordWrap: 'anywhere', cursor: 'pointer' }} onClick={() => {
                this.dialog.show('Data content', <>
                    <span style={{ wordWrap: 'anywhere' }}><Interpreter tx={{ type: 'json', data: tx.data }} /></span>
                </>)
            }}>See data content</span>
        });
        info.push({ key: '-', value: '-' });
        if (tx.output) {
            info.push({
                key: 'Output', value: <span style={{ wordWrap: 'anywhere', cursor: 'pointer' }} onClick={() => {
                    this.dialog.show('Data content', <>
                        <span style={{ wordWrap: 'anywhere' }}><Interpreter tx={{ type: 'json', data: tx.output }} /></span>
                    </>)
                }}>See data content</span>
            });
        }
        info.push({ key: 'Signature', value: <span style={{ wordWrap: 'anywhere' }}>{tx.sign}</span> });
        if (tx.validatorSign) {
            info.push({ key: 'Validator Signature', value: <span style={{ wordWrap: 'anywhere' }}>{tx.validatorSign}</span> });
        }
        await this.setState({
            info,
        })
        await setLoad(false);
    }

    render() {
        return (<>
            {this.state.notFound && <NotFound name="Transaction" />}
            {!this.state.notFound && <section className="block-explorer-section section bg-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Details for Transaction</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>Some basic information from this public transaction on the blockchain.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row m-bottom-70">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests table-detail">
                                    <tbody>
                                        {this.state.info.map((data, i) => <tr key={`info-${i}`}>
                                            <td><strong>{data.key}</strong></td>
                                            <td>{data.value}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
            <DialogModal ref={ref => this.dialog = ref} />
        </>)
    }
}