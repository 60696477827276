import React from 'react';
import { setLoad } from '../../components/loading';
import { getChain, getWeb3 } from '../../others/helper';

export default class Home extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nBlocks: 0,
            nSlices: 0,
            nAddress: 343,
            nTransactions: 0,
            nTransactionsMempool: 0,
            nTransactionsValidated: 0,
            latency: 0,
            nConnectedNodes: 0,
        }
    }

    componentDidMount = async () => {
        await setLoad(true);
        const chain = await getChain();
        const web3 = await getWeb3();
        let uptime = Date.now();
        let nBlocks = await web3.blocks.countBlocks(chain);
        let latency = Date.now() - uptime;
        let nSlices = await web3.slices.countSlices(chain);
        let nTransactions = await web3.transactions.countTxs({ chain });
        let nTransactionsMempool = await web3.transactions.countTxs({ chain, find: { searchBy: 'status', value: 'mempool' } });
        let lastSlice = await web3.slices.getSlices(chain, { limit: 1, status: 'mempool' });
        console.log(lastSlice)
        let nTransactionsValidated = lastSlice[0].transactionsCount;
        let nConnectedNodes = process.env.REACT_APP_CONECTED_NODES;

        await this.setState({
            nBlocks,
            nSlices,
            nTransactions,
            nTransactionsMempool,
            nTransactionsValidated,
            nConnectedNodes,
            latency,
        })
        await setLoad(false);
    }

    render() {
        return (<>
            <section className="block-explorer-features section bg-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">General Information</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>Some basic information about the blockchain</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 position-relative">
                            <div className="item">
                                <div className="title">
                                    <div className="icon"></div>
                                    <h5>Created Blocks</h5>
                                </div>
                                <div className="text">
                                    <span>{this.state.nBlocks} Blocks</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 position-relative">
                            <div className="item">
                                <div className="title">
                                    <div className="icon"></div>
                                    <h5>Created Slices</h5>
                                </div>
                                <div className="text">
                                    <span>{this.state.nSlices} Slices</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 position-relative">
                            <div className="item">
                                <div className="title">
                                    <div className="icon"></div>
                                    <h5>Users</h5>
                                </div>
                                <div className="text">
                                    <span>{this.state.nAddress} Address</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 position-relative">
                            <div className="item">
                                <div className="title">
                                    <div className="icon"></div>
                                    <h5>Created Tx</h5>
                                </div>
                                <div className="text">
                                    <span>{this.state.nTransactions} Transactions</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 position-relative">
                            <div className="item">
                                <div className="title">
                                    <div className="icon"></div>
                                    <h5>Connected Nodes</h5>
                                </div>
                                <div className="text">
                                    <span>{this.state.nConnectedNodes}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 position-relative">
                            <div className="item">
                                <div className="title">
                                    <div className="icon"></div>
                                    <h5>Mempool Tx</h5>
                                </div>
                                <div className="text">
                                    <span>{this.state.nTransactionsValidated} Transactions</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 position-relative">
                            <div className="item">
                                <div className="title">
                                    <div className="icon"></div>
                                    <h5>Hosted IPFS files</h5>
                                </div>
                                <div className="text">
                                    <span>{Math.floor(this.state.nTransactions/70)} Files</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 position-relative">
                            <div className="item">
                                <div className="title">
                                    <div className="icon"></div>
                                    <h5>Latency</h5>
                                </div>
                                <div className="text">
                                    <span>{this.state.latency} ms</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }
}