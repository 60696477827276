import React from 'react';
import { withRouter } from 'react-router-dom';
import NotFound from '../../components/not-found';
import { setLoad } from '../../components/loading';
import { formatLongString, getUptime, getWeb3 } from '../../others/helper';

class BlocksItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notFound: false,
            info: [],
            slices: [],
            page: 0,
            lastPage: 0,
        }
    }

    componentDidMount = async () => {
        await setLoad(true);

        let blockHash = this.props.match.params.id;
        const web3 = await getWeb3();
        let block = await web3.blocks.getBlockByHash(blockHash);
        console.log(block);
        if (!block) {
            await this.setState({ notFound: true });
            await setLoad(false);
            return;
        }

        let info = [];
        info.push({ key: 'Hash', value: block.hash });
        info.push({ key: 'Last Hash', value: <a href={`/blocks/${block.lastHash}`}>{block.lastHash}</a> });
        info.push({ key: 'Transactions Count', value: block.transactionsCount });
        info.push({ key: 'Height', value: block.height });
        info.push({ key: 'Created By', value: <a href={`/address/${block.from}`}>{block.from}</a> });
        info.push({ key: 'Slices', value: block.slices.length });
        info.push({ key: 'Version', value: block.version });
        info.push({ key: 'Created', value: new Date(block.created * 1000).toLocaleString() });
        info.push({ key: 'Signature', value: block.sign });
        if (block.externalTxID) {
            info.push({ key: 'Proof of Immutability', value: <a href={block.externalTxID[0]}>{block.externalTxID[0]}</a> });
        }
        let page = new URLSearchParams(this.props.location.search).get("page");
        page = page ? parseFloat(page) : 0;
        let slices = await web3.blocks.getSlicesFromBlock(blockHash);
        let amount = block.slices.length;
        slices = slices.filter((slice, i) => i >= page * 20 && i < (page + 1) * 20)

        await this.setState({
            slices,
            info,
            page,
            lastPage: Math.floor(amount / 20)
        })
        await setLoad(false);
    }

    render() {
        return (<>
            {this.state.notFound && <NotFound name="Block" />}
            {!this.state.notFound && <section className="block-explorer-section section bg-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Details for Block</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>Some basic information from this public block on the blockchain.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row m-bottom-70">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests table-detail">
                                    <tbody>
                                        {this.state.info.map((data, i) => <tr key={`info-${i}`}>
                                            <td><strong>{data.key}</strong></td>
                                            <td>{data.value}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Slices</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>Slices registered in this block.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests">
                                    <thead>
                                        <tr>
                                            <td>Height</td>
                                            <td>Hash</td>
                                            <td>Age</td>
                                            <td>Transactions</td>
                                            <td>Created by</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.slices.map(slice => <tr key={`id-${slice.hash}`}>
                                            <td>{slice.height}</td>
                                            <td><a href={`/slices/${slice.hash}`}>{slice.hash.substring(0, 20)}...</a></td>
                                            <td>{getUptime(slice.created)}</td>
                                            <td>{slice.transactions.length} Transactions</td>
                                            <td><a href={`/address/${slice.from}`}>{formatLongString(slice.from)}</a></td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                {this.state.page > 0 && <span>
                                    <a href={`/blocks/${this.props.match.params.id}?page=0`}>{`<<`}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href={`/blocks/${this.props.match.params.id}?page=${this.state.page - 1}`}>{`< previous page`}</a>
                                </span>}
                                {this.state.page < this.state.lastPage && <span style={{ float: 'right' }}>
                                    <a href={`/blocks/${this.props.match.params.id}?page=${this.state.page + 1}`}>{`next page >`}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href={`/blocks/${this.props.match.params.id}?page=${this.state.lastPage}`}>{`>>`}</a>
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>)
    }
}

export default withRouter(BlocksItem);