import React from 'react';
import { setLoad } from '../../components/loading';

export default class Authentication extends React.Component {

    componentDidMount = async () => {
        await setLoad(false);
    }

    render() {
        return (<>
            <section className="block-explorer-section section bg-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Authentication Web3</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>Cryptographic authentication system on the blockchain.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests">
                                    <thead>
                                        <tr>
                                            <td>Address</td>
                                            <td>Register</td>
                                            <td>Valid</td>
                                            <td>Transaction</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }
}