import { Web3 } from "@bywise/web3";
import { toast } from "react-toastify";

export const sleep = async function sleep(ms: number) {
    await new Promise((resolve) => {
        setTimeout(resolve, ms + 10);
    });
}

export const getUptime = (created: number) => {
    let uptime = (new Date().getTime()) / 1000 - created;
    let label = '';
    if (uptime < 60) {
        label = 'seconds';
    } else if (uptime < 60 * 60) {
        uptime = uptime / (60);
        label = 'minutes';
    } else if (uptime < 60 * 60 * 24) {
        uptime = uptime / (60 * 60);
        label = 'hours';
    } else {
        uptime = uptime / (60 * 60 * 24);
        label = 'days';
    }
    return `${Math.floor(uptime)} ${label}`;
}

export const formatLongString = (str = '') => {
    if (str.length <= 30) {
        return str
    }
    return `${str.substring(0, 10)}...${str.substring(str.length - 10)}`
}

export const getChain = (): string => {
    const chain = process.env.REACT_APP_CHAIN;
    if (chain === undefined) throw new Error('REACT_APP_CHAIN not defined');
    return chain;
}

export const getApi = (): string => {
    const url = process.env.REACT_APP_API;
    if (url === undefined) throw new Error('REACT_APP_API not defined');
    return url;
}

export const getWeb3 = async (): Promise<Web3> => {
    try {
        let nodes: any = process.env.REACT_APP_URL_NODE;
        if (!nodes) throw new Error('REACT_APP_URL_NODE not defined');
        nodes = nodes.split(',');
        let chain = process.env.REACT_APP_CHAIN;
        if (chain === undefined) throw new Error('REACT_APP_CHAIN not defined');

        let web3 = new Web3({
            initialNodes: nodes,
            debug: false
        });
        let web3Nodes = localStorage.getItem('web3-nodes');
        if (web3Nodes) {
            console.log('importConnections');
            await web3.network.importConnections(JSON.parse(web3Nodes));
        } else {
            console.log('connect');
            await web3.network.tryConnection();
        }
        localStorage.setItem('web3-nodes', JSON.stringify(web3.network.exportConnections()));
        return web3;
    } catch (err: any) {
        toast.error('Web3 connection failed');
        throw err;
    }
}