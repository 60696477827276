import React from 'react';
import { withRouter } from 'react-router-dom';
import { setLoad } from '../../components/loading';
import { formatLongString, getChain, getUptime, getWeb3 } from '../../others/helper';

class Slices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            slices: [],
            page: 0,
            lastPage: 0,
        }
    }

    componentDidMount = async () => {
        await setLoad(true);
        const chain = await getChain();
        const web3 = await getWeb3();
        let page = new URLSearchParams(this.props.location.search).get("page");
        page = page ? parseFloat(page) : 0;
        let slices = await web3.slices.getSlices(chain, { limit: 20, offset: page * 20 });
        let amount = await web3.slices.countSlices(chain);
        await this.setState({
            slices,
            page,
            lastPage: Math.floor(amount / 20)
        });
        await setLoad(false);
    }

    render() {
        return (<>
            <section className="block-explorer-section section bg-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Latest Slices</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests">
                                    <thead>
                                        <tr>
                                            <td>Hash</td>
                                            <td>Block Height</td>
                                            <td>Age</td>
                                            <td>Transactions</td>
                                            <td>Created by</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.slices.map(slice => <tr key={`id-${slice.hash}`}>
                                            <td><a href={`/slices/${slice.hash}`}>{slice.hash.substring(0, 20)}...</a></td>
                                            <td>{slice.blockHeight}</td>
                                            <td>{getUptime(slice.created)}</td>
                                            <td>{slice.transactions.length} Transactions</td>
                                            <td><a href={`/address/${slice.from}`}>{formatLongString(slice.from)}</a></td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                {this.state.page > 0 && <span>
                                    <a href={`/slices?page=0`}>{`<<`}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href={`/slices?page=${this.state.page - 1}`}>{`< previous page`}</a>
                                </span>}
                                {this.state.page < this.state.lastPage && <span style={{ float: 'right' }}>
                                    <a href={`/slices?page=${this.state.page + 1}`}>{`next page >`}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href={`/slices?page=${this.state.lastPage}`}>{`>>`}</a>
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }
}

export default withRouter(Slices);