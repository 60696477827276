import React from 'react';
import { Modal } from 'react-bootstrap';

export default class DialogModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            title: '',
            body: <div></div>,
        }
    }

    show = async (title, body) => {
        this.setState({
            showModal: true,
            title,
            body,
        });
    }

    handleClose = async () => {
        await this.setState({ showModal: false });
        if (this.props.handleClose) {
            this.props.handleClose();
        }
    }

    render() {
        return (<Modal show={this.state.showModal} size="lg" centered>
            <Modal.Header>
                {this.state.title}
            </Modal.Header>
            <Modal.Body>
                {this.state.body}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={this.handleClose}>OK</button>
            </Modal.Footer>
        </Modal>)
    }
}
