import React from 'react';
import NotFound from '../../components/not-found';
import { setLoad } from '../../components/loading';
import { formatLongString, getChain, getUptime, getWeb3 } from '../../others/helper';
import QRCode from "react-qr-code";
import IPFSIcon from '../../components/ipfs-icon';

export default class Address extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notFound: false,
            address: '0000000000000000000000',
            balance: '-',
            name: '',
            bio: '',
            url: '',
            photo: '',
            txs: [],
            count: '0',
        }
    }

    componentDidMount = async () => {
        await setLoad(true);
        let address = this.props.match.params.id;
        const web3 = await getWeb3();
        const chain = await getChain();
        let wallet = await web3.wallets.getWalletInfo(address, chain);
        let count = await web3.transactions.countTxs({ chain, find: { searchBy: 'address', value: address } });
        let txs = await web3.transactions.getTxs(chain, { find: { searchBy: 'address', value: address } });
        if (!wallet) {
            await this.setState({ notFound: true });
            await setLoad(false);
            return;
        }

        await this.setState({
            balance: wallet.balance,
            bio: wallet.bio,
            name: wallet.name,
            photo: wallet.photo,
            url: wallet.url,
            txs,
            count,
            address: address,
        })
        await setLoad(false);
    }

    render() {
        return (<>
            {this.state.notFound && <NotFound name="Address" />}
            {!this.state.notFound && <section className="block-explorer-section section bg-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Details for Address</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>Some basic information from this public address on the blockchain.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row m-bottom-70">
                        <div className="col-lg-9 col-md-9 col-sm-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests table-detail">
                                    <tbody>
                                        <tr>
                                            <td><strong>Address</strong></td>
                                            <td>{this.state.address}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Name</strong></td>
                                            <td>{this.state.name ? this.state.name : "Unknown"}</td>
                                        </tr>
                                        {this.state.bio && <tr>
                                            <td><strong>Bio</strong></td>
                                            <td>{this.state.bio}</td>
                                        </tr>}
                                        {this.state.url && <tr>
                                            <td><strong>URL</strong></td>
                                            <td><a href={this.state.url}>{this.state.url}</a></td>
                                        </tr>}
                                        <tr>
                                            <td><strong>No. Transactions</strong></td>
                                            <td>{this.state.count}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>Balance</strong></td>
                                            <td>{this.state.balance} BWS</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            {this.state.photo && <IPFSIcon className="img-fluid d-block mb-3 mx-auto" icon={this.state.photo} />}
                            <div className="qr">
                                <QRCode value={this.state.address} className="img-fluid d-block mx-auto" size={180} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Transactions</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>Transactions received or made through this address.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests">
                                    <thead>
                                        <tr>
                                            <td>Hash</td>
                                            <td>Age</td>
                                            <td>From</td>
                                            <td>To</td>
                                            <td>Tag</td>
                                            <td>Amount</td>
                                            <td>Fee</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.txs.map(tx => <tr key={`${tx.hash}`}>
                                            <td><a href={`/tx/${tx.hash}`}>{tx.hash.substring(0, 20)}...</a></td>
                                            <td>{getUptime(tx.created)}</td>
                                            <td><a href={`/address/${tx.from[0]}`}>{formatLongString(tx.from[0])}</a></td>
                                            <td><a href={`/address/${tx.to[0]}`}>{formatLongString(tx.to[0])}</a></td>
                                            <td>{tx.tag ? tx.tag : '-'}</td>
                                            <td>{tx.amount} BWS</td>
                                            <td>{tx.fee} BWS</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>)
    }
}