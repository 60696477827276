import React from 'react';
import NotFound from '../../components/not-found';
import { setLoad } from '../../components/loading';
import { getChain, getWeb3 } from '../../others/helper';
import BigNumber from 'bignumber.js';
import IPFSIcon from '../../components/ipfs-icon';

export const getUptime = (created) => {
    let uptime = (new Date().getTime()) / 1000 - created;
    let label = '';
    if (uptime < 60) {
        label = 'seconds';
    } else if (uptime < 60 * 60) {
        uptime = uptime / (60);
        label = 'minutes';
    } else if (uptime < 60 * 60 * 24) {
        uptime = uptime / (60 * 60);
        label = 'hours';
    } else {
        uptime = uptime / (60 * 60 * 24);
        label = 'days';
    }
    return `${Math.floor(uptime)} ${label}`;
}

export default class Token extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notFound: false,
            showMore: false,
            name: '',
            symbol: '',
            decimals: 0,
            publicInfo: [],
            events: [],
        }
    }

    componentDidMount = async () => {
        await setLoad(true);
        let address = this.props.match.params.id;
        const web3 = await getWeb3();
        const chain = await getChain();
        const publicInfo = [];

        let output = await web3.contracts.readContract(
            chain,
            address,
            'name',
            []
        );
        let name = output.output;


        output = await web3.contracts.readContract(
            chain,
            address,
            'owner',
            []
        );
        let owner = output.output;

        output = await web3.contracts.readContract(
            chain,
            address,
            'symbol',
            []
        );
        let symbol = output.output;

        output = await web3.contracts.readContract(
            chain,
            address,
            'url',
            []
        );
        let url = output.output;

        output = await web3.contracts.readContract(
            chain,
            address,
            'icon',
            []
        );
        let icon = output.output;

        output = await web3.contracts.readContract(
            chain,
            address,
            'totalSupply',
            []
        );
        let totalSupply = output.output;

        output = await web3.contracts.readContract(
            chain,
            address,
            'totalFee',
            []
        );
        let totalFee = (parseFloat(output.output) * 100).toFixed(2) + ' %';

        output = await web3.contracts.readContract(
            chain,
            address,
            'decimals',
            []
        );
        let decimals = parseInt(output.output);

        totalSupply = (new BigNumber(totalSupply)).dividedBy(new BigNumber(10 ** decimals)).toFixed() + ' ' + symbol;

        publicInfo.push({ key: 'Contract', value: address });
        publicInfo.push({ key: 'Name', value: name });
        publicInfo.push({ key: 'Total Supply', value: totalSupply });
        publicInfo.push({ key: 'URL', value: url });
        publicInfo.push({ key: 'Owner', value: owner, link: `/address/${owner}` });
        publicInfo.push({ key: 'Fees', value: totalFee });
        publicInfo.push({ key: 'Decimals', value: decimals });
        publicInfo.push({ key: 'Symbol', value: symbol });

        let events = await web3.contracts.getContractEventByAddress(
            chain,
            address,
            "Transfer"
        );
        events = events.map(event => {
            event.data = JSON.parse(event.data);

            event.data.amount = (new BigNumber(event.data.amount)).dividedBy(new BigNumber(10 ** decimals)).toFixed() + ' ' + symbol
            return event;
        })
        console.log('events', events)

        await this.setState({
            publicInfo,
            decimals,
            symbol,
            icon,
            name,
            events,
            showMore: events.length <= 5
        })
        await setLoad(false);
    }

    render() {
        return (<>
            {this.state.notFound && <NotFound name="Address" />}
            {!this.state.notFound && <section className="block-explorer-section section bg-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 ">
                            <div className="center-heading">
                                <h2 className="section-title">{this.state.name}</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>This is an Token generated at Bywise. You can check your information below.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row m-bottom-70">
                        <div className="col">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests table-detail">
                                    <tbody>
                                        {this.state.publicInfo.map((info, i) => <tr key={`info-${i}`}>
                                            <td><strong>{info.key}</strong></td>
                                            {info.link ?
                                                <td><a href={info.link}>{`${info.value}`}</a></td> :
                                                <td>{`${info.value}`}</td>
                                            }
                                        </tr>)}
                                        {this.state.icon && <tr>
                                            <td><strong>Icon</strong></td>
                                            <td>
                                                <IPFSIcon className="icon" icon={this.state.icon} />
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Last Transfers</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className={"table table-striped table-latests" + (this.state.showMore ? '' : ' show-more')}>
                                    <thead>
                                        <tr>
                                            <td>Index</td>
                                            <td>From</td>
                                            <td>To</td>
                                            <td>Amount</td>
                                            <td>Created</td>
                                            <td>Hash</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.events.filter((event, i) => i < 5 || this.state.showMore).map((event, i) => <tr key={`${event.id}`}>
                                            <td>{i}</td>
                                            <td><a href={`/address/${event.data.from}`}>{event.data.from.substring(0, 15)}...</a></td>
                                            <td><a href={`/address/${event.data.to}`}>{event.data.to.substring(0, 15)}...</a></td>
                                            <td>{event.data.amount}</td>
                                            <td>{getUptime(event.create)}</td>
                                            <td><a href={`/tx/${event.hash}`}>{event.hash.substring(0, 15)}...</a></td>
                                        </tr>)}
                                    </tbody>
                                </table>
                                {!this.state.showMore && <button className='center-text show-more-btn' onClick={() => this.setState({ showMore: true })}>
                                    <span>Show More</span>
                                </button>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>)
    }
}