import React from 'react';
import { withRouter } from 'react-router-dom';
import NotFound from '../../components/not-found';
import { setLoad } from '../../components/loading';
import { formatLongString, getUptime, getWeb3 } from '../../others/helper';

class SlicesItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notFound: false,
            info: [],
            txs: [],
            page: 0,
            lastPage: 0,
        }
    }

    componentDidMount = async () => {
        await setLoad(true);
        let sliceHash = this.props.match.params.id;
        const web3 = await getWeb3();
        let slice = await web3.slices.getSliceByHash(sliceHash);
        console.log('slice', slice);
        if (!slice) {
            await this.setState({ notFound: true });
            await setLoad(false);
            return;
        }
        let info = [];
        info.push({ key: 'Hash', value: slice.hash });
        info.push({ key: 'Height', value: slice.height });
        info.push({ key: 'Block Height', value: slice.blockHeight });
        info.push({ key: 'Transactions Count', value: slice.transactionsCount });
        info.push({ key: 'Created By', value: <a href={`/address/${slice.from}`}>{slice.from}</a> });;
        info.push({ key: 'Transactions', value: slice.transactions.length });
        info.push({ key: 'Version', value: slice.version });
        info.push({ key: 'End Flag', value: slice.end ? 'yes' : 'no' });
        info.push({ key: 'Created', value: new Date(slice.created * 1000).toLocaleString() });
        info.push({ key: 'Signature', value: slice.sign });

        let page = new URLSearchParams(this.props.location.search).get("page");
        page = page ? parseFloat(page) : 0;
        let txs = await web3.slices.getTransactionsFromSlice(sliceHash);
        let amount = slice.transactions.length;
        txs = txs.filter((tx, i) => i >= page * 20 && i < (page + 1) * 20)

        await this.setState({
            txs,
            info,
            page,
            lastPage: Math.floor(amount / 20)
        })
        await setLoad(false);
    }

    render() {
        return (<>
            {this.state.notFound && <NotFound name="Slice" />}
            {!this.state.notFound && <section className="block-explorer-section section bg-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Details for Slice</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>Some basic information from this public slice on the blockchain.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row m-bottom-70">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests table-detail">
                                    <tbody>
                                        {this.state.info.map((data, i) => <tr key={`info-${i}`}>
                                            <td><strong>{data.key}</strong></td>
                                            <td>{data.value}</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Transactions</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>Transactions registered in this slice.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests">
                                    <thead>
                                        <tr>
                                            <td>Hash</td>
                                            <td>Age</td>
                                            <td>From</td>
                                            <td>To</td>
                                            <td>Tag</td>
                                            <td>Amount</td>
                                            <td>Fee</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.txs.reverse().map(tx => <tr key={`id-${tx.hash}`}>
                                            <td><a href={`/tx/${tx.hash}`}>{tx.hash.substring(0, 20)}...</a></td>
                                            <td>{getUptime(tx.created)}</td>
                                            <td><a href={`/address/${tx.from}`}>{formatLongString(tx.from[0])}</a></td>
                                            <td><a href={`/address/${tx.to}`}>{formatLongString(tx.to[0])}</a></td>
                                            <td>{tx.tag ? tx.tag : '-'}</td>
                                            <td>{tx.amount} BWS</td>
                                            <td>{tx.fee} BWS</td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                {this.state.page > 0 && <span>
                                    <a href={`/slices/${this.props.match.params.id}?page=0`}>{`<<`}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href={`/slices/${this.props.match.params.id}?page=${this.state.page - 1}`}>{`< previous page`}</a>
                                </span>}
                                {this.state.page < this.state.lastPage && <span style={{ float: 'right' }}>
                                    <a href={`/slices/${this.props.match.params.id}?page=${this.state.page + 1}`}>{`next page >`}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href={`/slices/${this.props.match.params.id}?page=${this.state.lastPage}`}>{`>>`}</a>
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>)
    }
}

export default withRouter(SlicesItem);