import React from 'react';

export default class NotFound extends React.Component {

    render() {
        return (<>
            <section className="block-explorer-section section bg-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">{this.props.name} Not Found</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>Sorry, could not find this. Check the data and search again.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }
}