import React from 'react';
import NotFound from '../../components/not-found';
import { setLoad } from '../../components/loading';
import { getChain, getWeb3 } from '../../others/helper';
import QRCode from 'react-qr-code';
import IPFSIcon from '../../components/ipfs-icon';

export default class NFT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notFound: false,
            uri: '',
            name: '',
            qrcode: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXX',
            publicInfo: [],
        }
    }

    componentDidMount = async () => {
        await setLoad(true);
        let address = this.props.match.params.id;
        let tokenId = this.props.match.params.tokenId;
        const web3 = await getWeb3();
        const chain = await getChain();
        const publicInfo = [];

        let output = await web3.contracts.readContract(
            chain,
            address,
            'uri',
            [tokenId]
        );
        let uri = output.output;


        output = await web3.contracts.readContract(
            chain,
            address,
            'name',
            []
        );
        let name = output.output;

        output = await web3.contracts.readContract(
            chain,
            address,
            'info',
            [tokenId]
        );
        let infos = output.output;

        output = await web3.contracts.readContract(
            chain,
            address,
            'owner',
            []
        );
        let from = output.output;

        output = await web3.contracts.readContract(
            chain,
            address,
            'ownerOf',
            [tokenId]
        );
        let owner = output.output;

        publicInfo.push({ key: 'Generated By', value: from, link: `/address/${from}` });
        publicInfo.push({ key: 'Owner', value: owner, link: `/address/${owner}` });
        publicInfo.push({ key: 'NFT Address', value: address });
        publicInfo.push({ key: 'NFT Id', value: tokenId });

        let qrcode = window.location.href;

        for (let i = 0; i < infos.length; i++) {
            const info = infos[i];
            if (info.key && info.value) {
                publicInfo.push({ key: info.key, value: info.value, link: info.link });
                if (info.key === 'Web3.0 Traceability Document') {
                    qrcode = info.link;
                }
            }
        }

        await this.setState({
            publicInfo,
            uri,
            name,
            qrcode
        })
        await setLoad(false);
    }

    render() {
        return (<>
            {this.state.notFound && <NotFound name="Address" />}
            {!this.state.notFound && <section className="block-explorer-section section bg-bottom">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12 ">
                            <div className="center-heading">
                                <h2 className="section-title">{this.state.name}</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p>This is an NFT generated at Bywise. You can check your information below.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row m-bottom-70">
                        <div className="col-lg-3 col-md-3 col-sm-12 order-md-2 m-bottom-70">
                            {this.state.uri && <IPFSIcon className="img-fluid d-block mb-3 mx-auto" icon={this.state.uri} />}
                            <div className="qr">
                                <QRCode value={this.state.qrcode} className="img-fluid d-block mx-auto" size={180} />
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 order-md-1">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests table-detail">
                                    <tbody>
                                        {this.state.publicInfo.map((info, i) => <tr key={`info-${i}`}>
                                            <td><strong>{info.key}</strong></td>
                                            {info.link ?
                                                <td><a href={info.link}>{`${info.value}`}</a></td> :
                                                <td>{`${info.value}`}</td>
                                            }
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>)
    }
}