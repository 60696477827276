import React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setLoad } from './loading';
import { getChain, getWeb3 } from '../others/helper';

class SearchBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nBlocks: 0,
            hash: '',
        }
    }

    componentDidMount = async () => {
        const chain = await getChain();
        const web3 = await getWeb3();
        let nBlocks = await web3.blocks.countBlocks(chain);
        nBlocks -= 1;
        let base = '1';
        for (let i = 1; i < `${nBlocks}`.length; i++) {
            base += '0'
        }
        base = parseInt(base);
        nBlocks = Math.floor(nBlocks / base) * base;
        await this.setState({
            nBlocks,
        })
    }
    
    goTo = (url) => {
        this.props.history.push(url);
        setLoad(false);
    }
    
    find = async (event) => {
        event.preventDefault();
        await setLoad(true);
        const chain = await getChain();
        const web3 = await getWeb3();
        let hash = this.state.hash;
        if (/^[0-9]+$/.test(hash)) {
            let items = await web3.blocks.findBlocksFromHeight(hash, 1);
            if (items && items.length > 0) {
                this.goTo(`/blocks/${items[0].hash}`);
                return;
            }
        } else {
            let items = await web3.transactions.getTransactionByHash(hash);
            if (items) {
                this.goTo(`/tx/${hash}`);
                return;
            }
            items = await web3.wallets.getWalletInfo(hash, chain);
            if (items) {
                this.goTo(`/address/${hash}`);
                return;
            }
            items = await web3.blocks.getBlockByHash(hash);
            if (items) {
                this.goTo(`/blocks/${hash}`);
                return;
            }
            items = await web3.slices.getSliceByHash(hash);
            if (items) {
                this.goTo(`/slices/${hash}`);
                return;
            }
        }
        await setLoad(false);
        toast.error('Not Found');
    }

    render() {
        return (<>
            <section className="block-explorer-wrapper bg-bottom-center" id="welcome-1">
                <div className="block-explorer text">
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-lg-12 align-self-center">
                                <h1>Blockchain Explorer</h1>
                            </div>
                            <div className="offset-lg-3 col-lg-6">
                                <p>{this.state.nBlocks > 0 && `Up To Block ${this.state.nBlocks}`}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="input-wrapper">
                                    <form className="input" onSubmit={this.find}>
                                        <input
                                            type="text"
                                            placeholder="block, hash, transaction, etc..."
                                            value={this.state.hash}
                                            onChange={e => this.setState({ hash: e.target.value })}
                                        />
                                        <button type="submit"><i className="fa fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }
}

export default withRouter(SearchBar);