import React from 'react';
import { getWeb3 } from '../others/helper';

export default class Interpreter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            load: false,
            lines: [],
        }
    }

    printLine = (lines, level, line) => {
        lines.push(<>
            {(new Array(level)).fill({}).map((v, i) => <span key={`space-line-${lines.length}-${i}`}>&nbsp;&nbsp;</span>)}
            {line}
        </>)
    }

    printObj = async (lines, level, obj, init = null, end = '') => {
        if (level >= 8) {
            this.printLine(lines, level, <>
                {init}
                <span>{'...' + end}</span>
            </>);
        } else if (Array.isArray(obj)) {
            this.printLine(lines, level, <>
                {init}
                <span>{'['}</span>
            </>);
            level++;
            let size = obj.length - 1;
            for (let index = 0; index < obj.length; index++) {
                const item = obj[index];
                if (index < size) {
                    await this.printObj(lines, level, item, null, ',');
                } else {
                    await this.printObj(lines, level, item);
                }
            }
            level--;
            this.printLine(lines, level, ']' + end);
        } else if (typeof obj === 'string') {
            this.printLine(lines, level, <>
                {init}
                <span className="string">{`"${obj}"${end}`}</span>
            </>);
        } else if (typeof obj === 'number' || typeof obj === 'boolean' || obj === null || obj === undefined) {
            this.printLine(lines, level, <>
                {init}
                <span className="text-primary">{`${obj}${end}`}</span>
            </>);
        } else if (typeof obj === 'object') {
            let entires = Object.entries(obj);
            let size = entires.length - 1;
            this.printLine(lines, level, <>
                {init}
                <span>{'{'}</span>
            </>);
            level++;
            for (let index = 0; index < entires.length; index++) {
                let [key, value] = entires[index];
                if (key.toLocaleLowerCase() === 'bywise-txid') {
                    const web3 = await getWeb3();
                    let tx = await web3.transactions.getTransactionByHash(value);
                    if (!tx) {
                        this.printLine(lines, level, <>
                            <span className="key">{`"${key}": `}</span>
                            <span className="string">{`Not found transaction`}</span>
                        </>);
                    } else {
                        if (tx) {
                            if (index < size) {
                                await this.loadTX(lines, level, tx, <span className="key">{`"${key}": `}</span>, ',');
                            } else {
                                await this.loadTX(lines, level, tx, <span className="key">{`"${key}": `}</span>);
                            }
                        } else {
                            this.printLine(lines, level, <>
                                <span className="key">{`"${key}": `}</span>
                                <span className="string">{`"transaction not found"${end}`}</span>
                            </>);
                        }
                    }
                } else if (index < size) {
                    await this.printObj(lines, level, value, <span className="key">{`"${key}": `}</span>, ',');
                } else {
                    await this.printObj(lines, level, value, <span className="key">{`"${key}": `}</span>);
                }

            }
            level--;
            this.printLine(lines, level, '}' + end);
        }
    }

    loadTX = async (lines, level, tx, init = null, end = '') => {
        if (tx.type === 'string' || tx.type === 'json' || tx.type === 'command') {
            let json = tx.data;
            await this.printObj(lines, level, json, init, end);
        } else if (tx.type === 'file') {
            let json = tx.data;
            if (json.encode === 'base64') {
                this.printLine(lines, level, <>
                    {init}
                    <span>File {json.name}: <a download={json.name} href={json.content}>download</a>{end}</span>
                </>);
                if (json.type.startsWith('image/')) {
                    this.printLine(lines, level, <>
                        <img src={json.content} width="300" alt={json.name} />
                    </>);
                }
            } else {
                await this.printObj(lines, level, json, init, end);
            }
        } else {
            this.printLine(lines, level, <>
                <span>{JSON.stringify(tx.data)}</span>
            </>);
        }
    }

    componentDidMount = async () => {
        let tx = this.props.tx;
        let lines = [];
        let level = 0;
        await this.loadTX(lines, level, tx);
        this.setState({ lines })
    }

    render() {
        return (<>
            {this.state.lines.map((line, i) => <div key={`line-${i}`}>{line}</div>)}
        </>);
    }
}