import React from 'react';
import NotFound from '../../components/not-found';
import { setLoad } from '../../components/loading';
import { getChain, getWeb3 } from '../../others/helper';
import QRCode from "react-qr-code";
import IPFSIcon from '../../components/ipfs-icon';

export default class Web3Document extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notFound: false,
            showMore: false,
            address: '0000000000000000000000',
            publicInfo: [],
            icon: '',
            qrcode: 'XXXXXXXXXXXXXXXXXXXXX',
            txs: [],
        }
    }

    componentDidMount = async () => {
        await setLoad(true);
        let address = this.props.match.params.id;
        const web3 = await getWeb3();
        const chain = await getChain();
        const publicInfo = [];

        let qrcode = window.location.href;

        let output = await web3.contracts.readContract(
            chain,
            address,
            'name',
            []
        );
        publicInfo.push({ key: 'Name', value: output.output });

        output = await web3.contracts.readContract(
            chain,
            address,
            'countData',
            []
        );
        const countData = output.output;

        output = await web3.contracts.readContract(
            chain,
            address,
            'getPublicInfo',
            []
        );

        publicInfo.push({ key: 'Address', value: address });
        publicInfo.push({ key: 'Traceability data count', value: countData });
        Object.entries(output.output).forEach(([key, value]) => {
            publicInfo.push({ key, value });
            if (key === 'nft') {
                qrcode = value;
            }
        })
        console.log('getPublicInfo', publicInfo);

        output = await web3.contracts.readContract(
            chain,
            address,
            'getIcon',
            []
        );
        const icon = output.output;

        output = await web3.contracts.readContract(
            chain,
            address,
            'getData',
            ['0', '100']
        );
        const txs = output.output;

        await this.setState({
            publicInfo,
            icon,
            qrcode,
            txs,
            showMore: txs.length <= 5
        })
        await setLoad(false);
    }

    render() {
        return (<>
            {this.state.notFound && <NotFound name="Address" />}
            {!this.state.notFound && <section className="block-explorer-section section bg-bottom">
                <div className="container">

                    <div className="row m-bottom-70">
                        <div className="col-lg-3 col-md-3 col-sm-12 order-md-2 m-bottom-70">
                            <div className='img-fluid d-block mb-3 mx-auto'>
                                {this.state.icon && <IPFSIcon className="img-fluid d-block mb-3 mx-auto" icon={this.state.icon} />}
                            </div>
                            <div className="qr">
                                <QRCode value={this.state.qrcode} className="img-fluid d-block mx-auto" size={180} />
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-9 col-sm-12 order-md-1">
                            <div className="row">

                                <div className="col-lg-12">
                                    <div className="center-heading">
                                        <h2 className="section-title">Web3.0 Traceability Document</h2>
                                    </div>
                                </div>
                                <div className="offset-lg-3 col-lg-6">
                                    <div className="center-text">
                                        <p>This is a blockchain-based document that has all the information regarding the process below.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-striped table-latests table-detail">
                                    <tbody>
                                        {this.state.publicInfo.map((info, i) => <tr key={`info-${i}`}>
                                            <td><strong>{info.key}</strong></td>
                                            <td>
                                                {info.value.split('\n').map((value, j) => <p key={`info-${i}-${j}`}>{value}</p>)}
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="center-heading">
                                        <h2 className="section-title">Traceability</h2>
                                    </div>
                                </div>
                                <div className="offset-lg-3 col-lg-6">
                                    <div className="center-text">
                                        <p>Transactions containing the raw data from this document.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="table-responsive">
                                        <table className={"table table-striped table-latests" + (this.state.showMore ? '' : ' show-more')}>
                                            <thead>
                                                <tr>
                                                    <td>Index</td>
                                                    <td>Hash</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.txs.filter((hash, i) => i < 5 || this.state.showMore).map((hash, i) => <tr key={`${i}-${hash}`}>
                                                    <td>{i}</td>
                                                    <td><a href={`/tx/${hash}`}>{hash.substring(0, 20)}...</a></td>
                                                </tr>)}
                                            </tbody>
                                        </table>
                                        {!this.state.showMore && <button className='center-text show-more-btn' onClick={() => this.setState({ showMore: true })}>
                                            <span>Show More</span>
                                        </button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>}
        </>)
    }
}