import React from 'react';
import { withRouter } from 'react-router-dom';
import { setLoad } from '../../components/loading';
import { formatLongString, getChain, getUptime, getWeb3 } from '../../others/helper';

class Blocks extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            blocks: [],
            page: 0,
            lastPage: 0,
        }
    }

    componentDidMount = async () => {
        await setLoad(true);
        const chain = await getChain();
        const web3 = await getWeb3();
        let page = new URLSearchParams(this.props.location.search).get("page");
        page = page ? parseFloat(page) : 0;
        let blocks = await web3.blocks.getBlocks(chain, { limit: 20, offset: page * 20 });
        let amount = await web3.blocks.countBlocks(chain);

        await this.setState({
            blocks,
            page,
            lastPage: Math.floor(amount / 20)
        });
        await setLoad(false);
    }

    render() {
        return (<>
            <section className="block-explorer-section section bg-bottom">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="center-heading">
                                <h2 className="section-title">Latest Blocks</h2>
                            </div>
                        </div>
                        <div className="offset-lg-3 col-lg-6">
                            <div className="center-text">
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="table-responsive">
                                <table className="table table-striped table-latests">
                                    <thead>
                                        <tr>
                                            <td>Block Height</td>
                                            <td>Hash</td>
                                            <td>Age</td>
                                            <td>Transactions</td>
                                            <td>Slices</td>
                                            <td>Created by</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.blocks.map(block => <tr key={`id-${block.hash}`}>
                                            <td>{block.height}</td>
                                            <td><a href={`/blocks/${block.hash}`}>{block.hash.substring(0, 20)}...</a></td>
                                            <td>{getUptime(block.created)}</td>
                                            <td>{block.transactionsCount} Transactions</td>
                                            <td>{block.slices.length} Slices</td>
                                            <td><a href={`/address/${block.from}`}>{formatLongString(block.from)}</a></td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                {this.state.page > 0 && <span>
                                    <a href={`/blocks?page=0`}>{`<<`}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href={`/blocks?page=${this.state.page - 1}`}>{`< previous page`}</a>
                                </span>}
                                {this.state.page < this.state.lastPage && <span style={{ float: 'right' }}>
                                    <a href={`/blocks?page=${this.state.page + 1}`}>{`next page >`}</a>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <a href={`/blocks?page=${this.state.lastPage}`}>{`>>`}</a>
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }
}

export default withRouter(Blocks);