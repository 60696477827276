import React from 'react';

class Loading extends React.Component {

    static show = true;

    constructor(props) {
        super(props);
        this.state = {
            show: true
        }
    }

    componentDidMount = () => {
        this.showInterval = setInterval(() => {
            if (Loading.show !== this.state.show) {
                this.setState({ show: Loading.show });
            }
        }, 500);
    }

    componentWillUnmount = () => {
        clearInterval(this.showInterval);
    }

    render() {
        if (this.state.show) {
            return (<>
                <div className="loading-wrapper">
                    <div className="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </>)
        } else {
            return (<></>)
        }
    }
}

const setLoad = async (show) => {
    Loading.show = show;
}

export {
    Loading,
    setLoad,
}