import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './others/style.css';

// Components
import { Loading } from './components/loading';
import Header from './components/header';
import SearchBar from './components/search-bar';
import Footer from './components/footer';

// Pages
import Home from './pages/home';
import Domain from './pages/domain';
import Authentication from './pages/authentication';
import Certification from './pages/certification';
import Blocks from './pages/blocks';
import BlocksItem from './pages/blocks-item';
import Slices from './pages/slices';
import SlicesItem from './pages/slices-item';
import Transactions from './pages/transactions';
import TransactionsItem from './pages/transactions-item';
import Address from './pages/address';
import Web3Document from './pages/document';
import NFT from './pages/nft';
import Token from './pages/token';

class App extends React.Component {

  render() {
    return (<>
      <Loading />
      <BrowserRouter>
        <Header />
        <SearchBar />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/domain' component={Domain} />
          <Route exact path='/authentication' component={Authentication} />
          <Route exact path='/certification' component={Certification} />
          <Route exact path='/blocks/' component={Blocks} />
          <Route exact path='/blocks/:id' component={BlocksItem} />
          <Route exact path='/slices' component={Slices} />
          <Route exact path='/slices/:id' component={SlicesItem} />
          <Route exact path='/tx' component={Transactions} />
          <Route exact path='/tx/:id' component={TransactionsItem} />
          <Route exact path='/address/:id' component={Address} />
          <Route exact path='/token/:id' component={Token} />
          <Route exact path='/web3-document/:id' component={Web3Document} />
          <Route exact path='/nft/:id/:tokenId' component={NFT} />
        </Switch>
      </BrowserRouter>
      <Footer />
      <ToastContainer />
    </>
    );
  }
}
export default App;